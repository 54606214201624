<template>
  <div class="d-flex flex-row align-center">
    <itemCard
      :type="cardType"
      :data="computedData"
      :action="action"
      :actionText="$t(`action.pending-refund`)"
    />
  </div>
</template>

<script>
import listItem from "@/components/liffList/listItem.js";
import listRow from "@/components/list/mixins/listRow";
import orderCardItemMixins from "@/mixins/orderCardItem.js";

export default {
  mixins: [listItem, listRow, orderCardItemMixins],
  props: { cardBtnActions: Object },
  data: () => {
    return {
      useData: true,
    };
  },
  computed: {
    computedData() {
      return [
        {
          text: "",
          value: this.data.store_name,
          valueCol: 3,
        },
        {
          text: "",
          value: this.$helper.orderTimeFormat(this.data.created_at),
          class: this.statusColorClass,
          valueCol: 1,
        },
        {
          text: "",
          value:
            `NT$${this.data.total_amounts}` +
            (this.data.items
              ? `（${this.data.items.reduce(
                  (a, b) => a + b.count,
                  0
                )}項商品：${this.data.items
                  .map((v) => v.product + "*" + v.count)
                  .join(", ")}）`
              : ""),
        },
        {
          text: "訂單編號",
          value: this.data.order_no,
        },
      ];
    },
  },
  methods: {
    action() {
      if (!this.data.payment_record_id) {
        this.$snotify.error("此單無法操作完成退款");
        return;
      }
      this.cardBtnActions.click(this.data.payment_record_id);
    },
  },
};
</script>
